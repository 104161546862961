.layoutContainer {
    width: 100%;
    max-width: 2500px;
    height: 100%;
    display:flex ;
    flex-direction: column;
    align-items: center;
    // background: #f7f7f7;
  }
  
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 2500px;
    // padding: calc(var(--nav-height) + var(--navbar-margin-top)) 0 0 0;

    // @media screen and (min-width: 730px) {
    //   padding: calc(var(--nav-height) + var(--navbar-margin-top)) 0 0 0;
    // }
  
    // @media screen and (min-width: 1000px) {
    //   padding: calc(var(--nav-height) +  var(--navbar-margin-top)) 0 0 0;
    // }
  
  
    // @media screen and (min-width: 1400px) {
    //   padding: calc(var(--nav-height) + var(--navbar-margin-top)) 0 0 0;
    // }
  
    // @media screen and (min-width: 1700px) {
    //   // padding: calc(var(--nav-height) + 0px) 0 0 0;
    // }
  }
  
  .profileLayout {
    padding: 0 !important;
  }
  